#login{
    margin-top: 6rem;
    text-align: center;
}

.grid-login{
    display:grid;
    place-items: center;
}

.login-form{
    padding: 1.6rem 6% 1.6rem 6%;
    background-color: white;
}

.login-alert-anime-out{
    opacity: 0;
    width: 16rem;
    height: 3.5rem;
    transition: width .5s, height .5s, opacity .5s;
}

.login-alert-anime-in{
    width: 22rem;
    height: 3.5rem;
    opacity: 1;
    transition: width 1s, height 1s, opacity 1s;
}

/*
.login-alert-anime {
    animation-name: login-error-anime;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

@keyframes login-error-anime {
  0% {opacity: 0%;}
  100% {opacity: 100%}
}
*/