*, *:after, *:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;

}
  
/* Form Progress */
.customProgressBar {
    width: 100%;
    /* margin: 20px auto; */
    margin: 5% 0px;
    text-align: center;
}
.customProgressBar .circle, .customProgressBar .bar {
    display: inline-block;
    background: #fff;
    width: 55px; height: 55px;
    border-radius: 40px;
    border: 1px solid #d5d5da;
}

.customProgressBar .bar {
    position: relative;
    width: 18%;
    height: 6px;
    top: -40px;
    margin-left: -5px;
    margin-right: -5px;
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.customProgressBar .circle .label {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 42px;
    border-radius: 32px;
    margin-top: 5px;
    color: #b5b5ba;
    font-size: 20px;
}

.customProgressBar .circle .title {
    color: #b5b5ba;
    font-size: 13px;
    line-height: 30px;
    margin-left: -5px;
}

/* Done / Active */
.customProgressBar .bar.done, .customProgressBar .circle.done {
    background: #eee;
}

.customProgressBar .bar.active {
    background: linear-gradient(to right, #EEE 40%, #FFF 60%);
}

.customProgressBar .circle.done .label {
    color: #FFF;
    background: #00ff0d;
    box-shadow: inset 0 0 2px rgba(0,0,0,.2);
}

.customProgressBar .circle.done .title {
    color: #444;
}

.customProgressBar .circle.active .label {
    color: #FFF;
    background: #0c95be;
    box-shadow: inset 0 0 2px rgba(0,0,0,.2);
}

.customProgressBar .circle.active .title {
    color: #180cbe;
}