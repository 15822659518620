
.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.nav-link:hover .feather{
  animation: jiggle 1s linear;
}

@keyframes jiggle {
  0% {transform: rotate(0deg);}
  25% {transform: rotate(40deg);}
  50% {transform: rotate(0deg);}
  75% {transform: rotate(-40deg);}
  100% {transform: rotate(0deg);}
}

/*
 * Sidebar
 */
.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 5.2rem);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  margin-bottom: 1rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.bg-light {
  background-color: white !important;
}

.sidebar .nav-link {
  color: gray;
  font-weight: 600;
}

.sidebar .nav-link .feather {
  margin-right: 1rem;
  color: #999;
}

.sidebar .nav-link.active .feather {
  color: var(--primary) !important;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

 .navbar {
  border-bottom: 0px solid white;
  /*background-color: #4b1805 !important;*/
 }

.navbar-color .navbar-brand {
  border-bottom: 0px solid white;
 }

.navbar-brand {
  text-align: justify;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
}

.navbar .navbar-toggler {
  right: 1rem;
}

.navbar-toggler-icon {
  pointer-events: none;
}

.ml-sm-auto {
  margin-left: auto !important;
}

.feather {
  width: 24px;
  height: 24px;
  vertical-align: text-bottom;
}

.nav-sub {
  padding: .1rem .5rem;
  margin-left: 1.5rem;
}

.nav-item {
  
  cursor: pointer;
  height: max-content;
}

.nav-link{
  padding: 0.5rem 0rem 0.5rem 1rem;
  color: black;
}

.nav-link.active .ribbon {
  margin-left:auto;
  width: 5px;
  height: 24px; 
  background-color: var(--primary);
  float: right;
}

.nav-link.active .ribbonsub {
  margin-left:auto;
  width: 5px;
  height: 24px; 
  background-color: gray;
  float: right;
}

.nav.nav-pills {
  flex-wrap: nowrap;
}