@import "spinner.css";
@import "tooltip.css";

:root {
  --primary: #1996D4;
  --secondary: #13a89e;
  --secondary-gradient: #7abf52;
  --tertiary: #36393d;
  --dark: #36393d;
  --light: #f8f9fa;
  --pdf-viewer-height: 69vh;
}

#root{
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  min-width: 256px; /* Suppose you want minimum width of 1000px */
  width: auto !important; /* Firefox will set width as auto */
  width: 256px; /* As IE6 ignores !important it will set width as 1000px; */
  background-color: var(--light);
  font-size: 0.875rem;
  height:100%;
}

a.alt {
  color: black;
  text-decoration: none;
}

a:hover{
  color: white;
}

#background-obj {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1000;
}

#left-square-obj,
#right-square-obj {
  position: absolute;
  width: 500px;
  height: 500px;
  opacity: 0.15;
  background: linear-gradient(
    to bottom right,
    var(--primary),
    var(--secondary)
  );
  transform: rotate(45deg);
  border-radius: 10%;
}

#left-square-obj {
  left: -150px;
  top: 300px;
}

#right-square-obj {
  left: 90%;
  top: 0px;
}

#error-box {
  margin-top: 6rem;
  padding: 1.6rem 6% 1.6rem 6%;
  text-align: center;
  background-color: var(--light);
}

.grid {
  display: grid;
}

#content-box {
  padding: 1rem;
}

/* Toggle Css */
.hide-main-box {
  margin-left: auto;
  margin-right: auto;
  transition: width 0.5s ease-in-out;
}
.show-main-box {
  /* margin : auto; */
  margin-left: auto;
  margin-right: auto;
  transition: width 0.5s ease-in-out;
}
/* Toggle Css */

.result {
  width: 57px;
  height: 57px;
  vertical-align: middle;
  border-radius: 50px;
  object-fit: cover;
}

/* Colors */
.dark-bg {
  background-color: var(--dark) !important;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
}

.ccolor {
  color: var(--primary);
}

.ccolor-bg {
  background-color: var(--secondary);
}

.acolor {
  color: var(--primary);
}

.acolor-bg {
  color: white;
  background-color: var(--primary);
}

/* Tabulator */
.tabulator-row.tabulator-selectable:hover{
  color: white;
  background-color: var(--primary) !important;
}

.tabulator-row.tabulator-selectable:hover a{
  color: white;
}

.tabulator {
  text-align: center !important;
  border: 0px solid black;
  border-radius: 10px;
  box-shadow: 0px 0px 15px grey;
}

.tabulator-col-content {
  text-align: center !important;
}

.formatterCell {
  min-height: 50px;
}

/* Employee */
.tab-grid {
  display: grid;
  grid-template-columns: auto auto;
}

.wide-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-check-label {
  padding-top: 0rem;
}

.float-right {
  float: right;
}

label {
  padding-top: 0.5rem;
}

/* Loader */
.loader {
  display: grid;
  place-items: center;
  width: 100%;
  height: var(--pdf-viewer-height);
  opacity: 1;
}

.loader-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

/* Report */
#pdf-viewer {
  width: 100%;
  height: var(--pdf-viewer-height);
}

/* Buttons */
.btn {
  margin: 0.5rem;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:target,
.btn-primary:active:focus {
  background: linear-gradient(
    to left,
    var(--secondary-gradient),
    var(--secondary)
  );
  border-color: var(--light);
  border-radius: 30px;
  padding: 10px 50px;
}

.btn-primary:focus,
.btn-primary:active:focus {
  box-shadow: 0 0 0 5px rgba(19, 168, 158, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: linear-gradient(
    to left,
    var(--secondary-gradient),
    var(--secondary)
  );
  border-color: linear-gradient(
    to left,
    var(--secondary-gradient),
    var(--secondary)
  );
}

.btn-primary:hover {
  background-color: linear-gradient(
    to left,
    var(--secondary-gradient),
    var(--secondary)
  );
  border-color: linear-gradient(
    to left,
    var(--secondary-gradient),
    var(--secondary)
  );
}

.btn-secondary {
  border-radius: 30px;
  padding: 10px 50px;
}

.btn-info,
.btn-info:active,
.btn-info:focus,
.btn-info:target,
.btn-info:active:focus {
  color: white;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 30px;
  padding: 10px 50px;
}

.btn-info:focus,
.btn-info:active:focus {
  box-shadow: 0 0 0 5px rgba(250, 166, 26, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: white;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-info:hover {
  color: white;
  background-color: var(--primary);
  border-color: var(--primary);
}

/*
 * Nav Pills
 */
.nav-pills .nav-item .nav-link {
  padding: 10px 25px;
}

.nav-pills .nav-item {
  box-shadow: 0px 0px 2px gray;
  border-radius: 0px;
}

.nav-pills .nav-item:first-of-type {
  border-radius: 15px 0px 0px 15px !important;
}

.nav-pills .nav-item:last-of-type {
  border-radius: 0px 15px 15px 0px !important;
}

.nav-pills .nav-item .nav-link.active {
  background-color: var(--primary);
  border-radius: 0px;
}

.nav-pills .nav-item:first-of-type .nav-link.active {
  background-color: var(--primary);
  border-radius: 15px 0px 0px 15px;
}

.nav-pills .nav-item:last-of-type .nav-link.active {
  background-color: var(--primary);
  border-radius: 0px 15px 15px 0px;
}

.nav-pills .nav-item .nav-link,
.nav-item .nav-link:active {
  transition: unset;
}

/*
 * Widgets
 */
.alert-anime-out {
  padding: 1rem;
  opacity: 0;
  width: 16rem;
  height: 3.5rem;
  transition: width 1s, height 1s, opacity 1s;
}

.alert-anime-in {
  padding: 1rem;
  width: 22rem;
  height: 3.5rem;
  opacity: 1;
  transition: width 1s, height 1s, opacity 1s;
}

.success-anime-out {
  padding: 1rem;
  opacity: 0;
  width: 16rem;
  height: 3.5rem;
  transition: width 1s, height 1s, opacity 1s;
}

.success-anime-in {
  padding: 1rem;
  width: 22rem;
  height: 3.5rem;
  opacity: 1;
  transition: width 1s, height 1s, opacity 1s;
}

.preview-img{
    max-width: 70%;
    max-height: 70%;
}

/* Applicant Login */
#appLogContainer{
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* background-color: #faa61a; */
}

#logBox{
  margin-top: -10%;
  /* height: 40%; */
  width: 25%;
  padding: 0% 2%;
  /* border: 1px solid rgba(0, 0, 0, 0.301); */
  border-radius: 5%;
  box-shadow: 10px 10px 7px #aaaaaa8e;
  background-color: #dfa72f45;
  /* background-color: #f9f1d7; */
}

.loginBtn{
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}

#logBox h5{
  margin: 10% 0%;
  text-align: center;
}

/* Applicant */
.applicantPageLayout{
    margin: 3% auto;
    max-width: 1000px;
    color:black;
    font-size: 15px;
    font-weight: bold;
}

.custom-btn{
  margin-right: 5%;
}

.applicant_header{
  display: flex;
}

.inputsLayout{
    margin-left: 8%;
}

.jobApplicationTwoBtn{
    display:flex;
    justify-content: space-between;
    margin-top: 5%;
}

.jobApplicationNextBtn{
    display:flex;
    justify-content: end;
    margin-top: 5%;
}

.jobApplicationAddBtn{
    display: flex;
    margin-top: 5%;
    justify-content: flex-end;
}

.jobApplicationAddBtn button{
    font-weight: bold;
    font-size: 15px;
}

.jobApplcationContentBox{
    margin-bottom: 10%;
}

.jobApplcationContentBox h5{
    text-decoration: underline;
}

.customP{
  padding-top: 0.3rem
}

.customP button{
  margin-left: 10px;
  border: none;
  text-decoration: underline;
  color:blueviolet;
  font-size: medium;
}

.customP p{
  font-size: medium;
  font-weight: 300;
  margin-left: 10px;
}

/* Applicant Summary */
.infobox{
  margin: 5% 3%;
  padding: 2% 2% 2% 2%;
  box-shadow: 10px 10px 7px #aaaaaa8e;
  background-color: #faf0e6;
  border-top: 0.5px double #cdb891;
  border-left: 10px solid #cdb891;
  font-size: medium;
}

.infobox hr{
  margin: 3% 0%;
}

.summaryBtn{
  margin-bottom: 5%;
  margin-right: 3%;
}

.infobox h5{
  /* margin-top: 1%; */
  margin-bottom: 3%;
}

.summaryHeader{
  margin: 3% 0% -3% 3%;
  font-size: large;
}

.miniHeader{
    display: flex;
    justify-content: space-between;
}

.miniHeader button{
    color:#004450;
}

.miniHeader button:hover{
    text-decoration: underline;
}
.preview-img {
  max-width: 70%;
  max-height: 70%;
}
/* .allmodule {
  font-size: 25px;
} */

.Question-word li {
  font-size: 25px;
  padding: 20px;
  margin: 20px 0;
  outline: thick double orange;
  /* background-color: orange; */
  border-radius: 5px;
  cursor: pointer;
}

.Question-word li.clicked {
  background-color: green;
  color: white;
}

.Question-word li:hover {
  background-color: darkorange;
  color: white;
}


.align-right {
  float: right;
}

.align-left {
  float: left;
}

.align-center{
  text-align:center;
}

.Center {
  width:200px;
  height:200px;
  position: fixed;
  background-color: blue;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
}

.fail-card-border{
  border: dotted;
  border-color: red;
  padding: 10px 10px 10px 10px;
}


.pass-card-border{
  border: dotted;
  border-color: green;
  padding: 10px 10px 10px 10px;
}



.button-option {
  appearance: none;
  background-color: transparent;
  border: 2px solid orange;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  /* font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  /* text-align: center; */
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-option:disabled {
  pointer-events: none;
}

.button-option:hover,.option-active {
  color: #fff;
  background-color: orange;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-option:active {
  box-shadow: none;
  background-color: orange;
  transform: translateY(0);
}


.qus-btn {
  appearance: none;
  background-color: transparent;
  /* border: 2px solid #20ab95; */
  background-color: #20ab95;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  /* font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 24px 24px ;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.qus-btn:disabled {
  pointer-events: none;
}

.qus-btn:hover {
  color: #fff;
  background-color: #20ab95;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.qus-btn:active {
  box-shadow: none;
  transform: translateY(0);
}

.qus-mark {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  border-radius: 50px;
  object-fit: cover;
}

.progress-bar {
  width: 100%;
  height: 15px;
  background-color: #ccc;
  margin-top: 10px;
  border-radius: 10px;
}

.progress {
  border-radius: 10px;
  width: 0;
  height: 100%;
  background-color: #4CAF50;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: x-small;
  transition: width 0.9s ease-in-out;
}

.rateTable {
  width: 100%;
  border: 1px solid black;
  background-color: white;
  margin-bottom: 3%;
  text-align: center;
}

.rateTable td, th{
  width:20%;
  border:1px solid black
}

.rateTable th{
  background-color: #0dcaf048;
}

.submit-btn {
  appearance: none;
  background-color: transparent;
  /* border: 2px solid #20ab95; */
  background-color: gray;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  /* font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600; */
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 24px 24px ;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.submit-btn :disabled {
  pointer-events: none;
}

.submit-btn :hover {
  color: #fff;
  background-color: #20ab95;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.submit-btn :active {
  box-shadow: none;
  transform: translateY(0);
}

/* main card */


.custom .card:hover {
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1),
                    0 10px 8px rgba(0, 0, 0, 0.015);
}

.custom .card {
  border-radius: 40px;  
  overflow: hidden;
  border: 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06),
              0 2px 4px rgba(0, 0, 0, 0.07);
  transition: all 0.15s ease;
}

.card-body .card-title {
             /* letter-spacing: 0.3px; */
             /* font-size: 24px; */
             color: #121212;
}

.card-text {
        /* font-family: 'Lato', sans-serif;
        font-weight: 400; */
        /* font-size: 15px; */
        /* letter-spacing: 0.3px; */
        color: #4E4E4E;

}

.card .container {
      /* width: 88%; */
      /* background: #F0EEF8; */
      border-radius: 30px;
      /* height: 140px; */
      display: flex;
      align-items: center;
      justify-content: center;
}

.container:hover > img {
                  transform: scale(1.2);
}

.container img {
           padding: 75px;  
          margin-top: -40px;
          margin-bottom: -40px;
         transition: 0.4s ease;
         cursor: pointer;
}

.unlock-bg {
  background: #c4e6c4;
}

.lock-bg{
  background-color: lightgray;
}

.img-size{
  width: 200px;
  height: 200px;
}

/* main card */


/* video  */

.video-show{
  visibility:visible;
}

.video-hide{
  visibility:hidden;
}

/* video  */

.vl {
  border-left: 2px solid gray;
  height: 40px;
}

.score-text{
  color: gray;
}


/* Staff Appraisal */
.staffSpace{
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}

.staffGeneral{
  border: 1px solid #aaaaaa8e;
  background-color: white;
  box-shadow: 10px 10px 7px #aaaaaa8e;
}

.staffOtherDiv{
  margin-top: 5%;
}

.staffOtherSDiv{
  margin-top: 3%;
  margin-bottom: 3%;
}

/* Hazard  */

.img-width{
  width:100px;
}

.card-background {
  background-color: #c6e4c2;
}

ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"][id^="activity_"] {
  display: none;
}

input[type="checkbox"][id^="process_"] {
  display: none;
}

input[type="checkbox"][id^="consequence_"] {
  display: none;
}



#hazard label {
  border: 1px solid lightgrey;
  padding: 18px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

#hazard-no-drop label {
  border: 1px solid lightgrey;
  padding: 18px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: no-drop;
}

#hazard label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid lightgrey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

#hazard-no-drop label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid lightgrey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

#hazard label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

#hazard-no-drop label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

#hazard :checked +   label {
  border-color: #ddd;
}

#hazard :checked +  label:before {
  content: "✓";
  background-color: #24ac91;
  transform: scale(1);
}

#hazard-no-drop :checked +  label:before {
  content: "✓";
  background-color: #24ac91;
  transform: scale(1);
}

#hazard :checked +  label img {
  transform: scale(0.9);
  z-index: -1;
}


#hazard-no-drop :checked +  label img {
  transform: scale(0.9);
  z-index: -1;
}

textarea
{
  width:100%;
  height: 100%;
}

.textwrapper
{
  border:1px solid #999999;
}

.specify-padding{
  padding-left:1.3rem;
}

.sip-font{
  font-size: smaller;
}

#sip label {
  cursor:pointer;
}

#observation label{
  cursor: pointer;
}

/* Hazard  */


.evalDivLabel{
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #0d77f0; */
}

.evalDivInnerLabel{
  /* border: 1px solid black; */
  font-size: 95%;
  padding: 10%;
  background-color: #00a56e;
  box-shadow: 10px 10px 7px #aaaaaa8e;
  color: white;
}

.evalDivContent{
  padding-top: 1%;

}

/* Appraisal Table  */

.apDiv{
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}

.aTable{
  width: 100%;
  text-align: center;
}

.aTable svg:hover{
  cursor: pointer;
}

.aTable th{
  background-color: #0d77f0;
  color: white;
}

.aTable tr{
  height: 30px;
}

.aTable td{
  border:1px solid #aaaaaaea;
}

/* Appraisal Table  */


/* HSE Table  */

.hseDiv{
  padding: 0% 5%;
}

.hseTable{
  width: 100%;
  text-align: center;
}

.hseTable svg:hover{
  cursor: pointer;
}

.hseTable th{
  background-color: #0d77f0;
  color: white;
  padding: 1%;
}

.hseTable tr{
  height: 30px;
}

.hseTable td{
  border:1px solid #aaaaaaea;
  padding: 1%;

}

.hseTable th:nth-child(1){
  width: 10%;
}

.hazardFormBtn{
  margin-right: 10%;
}

/* HSE Table  */


/* Fixed Asset div */

.faDiv{
  margin-top: 1%;
  margin-bottom: 1%;
}

.faBorder{
  border: 1px dotted black;
  padding: 2% 3%
}

/* Fixed Asset div */
  

/* Helpdesk */
.mainDiv{
  border:1px solid #aaaaaa8e;
  padding: 3%;
  width: 100%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 7px #aaaaaa8e;
  margin-top: 3%;
  background-color: white;
}

.mainDiv h6{
  margin-bottom: 5%;
  text-decoration: underline;
}

.emotionDiv{
  display:flex;
  margin-bottom: 5%;
  justify-content: center;
  width: 100%;
}

.innerEmotionDiv img{
  width: 8dvw;
  padding-left: 10%;
  padding-right: 10%;
  opacity: 0.3;
}

.unhoverableEmotionDiv img{
  width: 8dvw;
  padding-left: 10%;
  padding-right: 10%;
  opacity: 0.3;
}

.innerEmotionDiv img:hover{
  cursor: pointer;
  opacity: 1;
}

/* Helpdesk */

/* IT Requisition */

.contentMainDiv{
  display:flex;
  border: 1px solid rgb(182, 182, 182);
  padding:2%;
  background-color: rgb(229, 252, 253);
  box-shadow: 10px 10px 7px #aaaaaa8e;
}

.hardSoftDiv{
  width: 50%;
  padding: 1% 3%;
}

.softwareBox{
  margin-top: 3%;
  border: 1px solid rgb(182, 182, 182);
  background-color: white;
  padding: 4%;
}


/* IT Requisition */


/* canva css */

canvas{
  margin: 4%;
  width: 100%;
}

.canvaHeader{
  /* width: 100%; */
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.canvaHeader h5{
  color: #1996D4;
}

/* canva css */

/* new CSS update v1 from Cullen*/

.logo-fix{
  width: 90px;
  margin-left: 1rem;
}

.logo-login{
  height: 73px;
  width: 9rem;
}

/* Header */

.newNavHeader{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 4px 0px
}

.profile {
  width: 2.5rem;
  height: 2.5rem;
  vertical-align: middle;
  border-radius: 50%;
  object-fit: cover;
}

.profile-grid {
  padding-left: 1rem;
  padding-right: 1rem;
  display: grid;
  grid-template-columns: auto auto;
}

.profile-bar-flex {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-right: 2rem;
  gap: 10%;
}

.menuDisplay{
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  padding: 0% 15%;
}

.menuDisplay p{
  height:100%;
  /* width: 10%; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  cursor: default;
}

.dropdownBackground{
  display: none;
  position: absolute;
  backdrop-filter: blur(8px);
  z-index: 1900;
  top: 50px;
  overflow: hidden;
  height: 94vh;
  width: 100%;
}

.dropdownContent{
  transition: min-height 0.4s ease-in-out;
  display: block;
  position: absolute;
  background-color: white;
  top: 50px;
  /* color: #F5FCFF; */
  height: 0px;
  min-height: 0px;
  width: 100%;
  overflow:hidden;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2000;
  /* padding:2%; */
}

.dropdownContainer p{
  font-weight: bold;
}

.dropdownContainer{
  height:100%;
  width: 100%;
  position: relative;
  display: inline-block;
}

.fadeInText a, .userAccBox a{
  text-decoration: none;
  color:black
}

.fadeInText a:hover{
  color: #1996D4;
}

.fadeInText{
  animation: fadeIn 1.5s;
}

.fadeInText h5{
  font-weight: 400;
  color: #1996D4;
  margin-bottom: 8%;
}

.fadeInText p{
  font-weight: bold;
  line-height: 50%;
}

@keyframes fadeIn{
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.userAccBox{
  transition: min-height 0.3s ease-in-out;
  display:block;
  position: absolute;
  width: 10%;
  background-color: white;
  top:50px;
  right:0px;
  height: 0px;
  min-height: 0px;
  overflow: hidden;
  z-index: 2000;
}

.userAccBox p{
  font-weight: bold;
  height: 50%;
  margin: 0
}

.userAccBox a{
  display:flex;
  align-items: center;
  padding-left: 10%;
  width: 100%;
  height:50%;
}

.userAccBox a:hover{
  transition: all 0.4s ease;
  /* transition: color 0.3s ease-in-out; */
  background-color: #1996D4;
  color: white;
}

.dashboardContainer{
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 5%;
  width: 100%;
  font-weight: bold;
  border: 0.2px solid #cdecfd;
  transition:all 1s ease-in-out;
  margin-bottom: 10%;
}

.dashboardContainer h5{
  text-align: center;
  margin-bottom: 5%;
}

.dashboardContainer:hover{
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  transition: all 0.5s ease-in-out;
}

.dashboardContainer hr{
  border: 1px solid #51C2B7;
  opacity: 1;
  width: 100%;
}

.dashboardContainer a{
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  color: #0F4878;
  text-decoration: none;
}

.dashboardContainer a p{
  margin-top: 15px;
  margin-right: 20px;
}

.dashboardContainer a:hover{
  color: #1996D4
}

.flexDisplay{
  display:flex;
  align-items: center;
  justify-content: center;
  color: #1996D4;
  width: 100%;
}

.flexDisplay h1{
  margin-left: 15px;
}

.flexDisplay2{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flexDisplay2 a{
  display:flex;
  justify-content: space-evenly;
  align-items: normal;
  color: #0F4878;
  text-decoration: none;
}

.flexDisplay2 a p{
  margin-top: 2px;
  margin-right: 20px;
}

.colFlexDisplay{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1996D4;
  width: 100%;
}

.colFlexDisplay h1{
  margin-left: 0;
}

.dashboardContainer span{
  color: #1996D4;
}

.divDesc{
  text-align: center;
}

.innerSmallDiv{
  background-color: #ecf8fd;
  border-radius: 20px;
  padding: 5% 2% 3% 2%;
  margin-bottom: 5%;
}

.innerRoundDivMain{
  background-color: #1996D4;
  color:white;
  padding:10%;
  border-radius: 50%;
  margin-bottom: 8%;
}

.innerRoundDivSub{
  background-color: #51C2B7;
  color:white;
  padding:10%;
  border-radius: 50%;
  margin-bottom: 8%;

}

.loginBgBase{
  background-color: rgb(231, 249, 255);
  /* background-color: #1996D4;
  opacity: 0.3; */
  width: 100vw;
  height: 100vh;
  z-index: 0;
  /* position: relative; */
}

.loginWave1{
  position: absolute;
  top: 10%;
  left: 20%;
  z-index:1;
  /* background-color: #00a56e; */
  border: 1px solid #1996D4;
  width: 60vw;
  height: 80vh;
  animation-duration: 3s;
  /* animation-iteration-count: 3; */
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-name: shrink;
}

.loginWave2{
  position: absolute;
  top: 10%;
  left: 20%;
  z-index:1;
  /* background-color: #00a56e; */
  border: 1px solid #1996D4;
  width: 60vw;
  height: 80vh;
  animation-duration: 3s;
  /* animation-iteration-count: 3; */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: shrink2;
}

.loginWave3{
  position: absolute;
  top: 10%;
  left: 20%;
  z-index:1;
  /* background-color: #00a56e; */
  border: 1px solid #1996D4;
  width: 60vw;
  height: 80vh;
  animation-duration: 3s;
  /* animation-iteration-count: 3; */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: shrink3;
}

@keyframes shrink {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.1);
    opacity: 0;
  }
}

@keyframes shrink2 {
  from {
    transform: scale(0.95);
    opacity: 1;
  }

  to {
    transform: scale(1.05);
    opacity: 0;
  }
}

@keyframes shrink3 {
  from {
    transform: scale(0.85);
    opacity: 1;
  }

  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

.loginMainContainer{
  top:10%;
  left: 20%;
  background-color: white;
  position: absolute;
  width: 60vw;
  height: 80vh;
  z-index: 2;
  display: flex;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

}

.loginLeft{
  width: 60%;
}

.loginLeft img{
  width: 100%;
  height: 100%;
}

.loginRight{
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 4%;
  width: 40%;
}

.loginRight img{
  width: 80%;
  margin-bottom: 10%;
}

.loginRight form{
  width: 100%;
}

.loginRight form label{
  margin-top: 2%;
}

.newloginBtn{
  padding: 3% 0%;
  margin-top: 15%;
  background-color: #1996D4;
  cursor: pointer;
  color: white;
  font-size: large;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  border: 0px;
}

.newloginBtn:hover{
  background-color: #3daae0;
}

.rollinigDiv{
  position:absolute;
  left: 0;
  width: auto;
}


/* Dashboard slide in css */

.slideInLeft {
  animation-name: slideInLeft;
}

.slideInRight {
  animation-name: slideInRight;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated2 {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}
.animated3 {
  animation-duration: 2s;
  animation-fill-mode: both;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);

  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
/* Dashboard slide in css */

/* Header */

/* new CSS update v1 from Cullen*/

