.gg-spinner {
    transform: scale(var(--ggs,1))
}

.gg-spinner,
.gg-spinner::after,
.gg-spinner::before {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 20px;
    height: 20px
}

.gg-spinner::after,
.gg-spinner::before {
    content: "";
    position: absolute;
    border-radius: 100px
}

.gg-spinner::before {
    animation: spinner 1s
    cubic-bezier(.6,0,.4,1) infinite;
    border: 3px solid transparent;
    border-top-color: currentColor
}

.gg-spinner::after {
    border: 3px solid;
    opacity: .2
}

@keyframes spinner {
    0% { transform: rotate(0deg) }
    to { transform: rotate(359deg) }
}

.gg-spinner-large {
    transform: scale(var(--ggs,1))
}

.gg-spinner-large,
.gg-spinner-large::after,
.gg-spinner-large::before {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100px;
    height: 100px
}

.gg-spinner-large::after,
.gg-spinner-large::before {
    content: "";
    position: absolute;
    border-radius: 100px
}

.gg-spinner-large::before {
    animation: spinner 1s
    cubic-bezier(.6,0,.4,1) infinite;
    border: 3px solid transparent;
    border-top-color: currentColor
}

.gg-spinner-large::after {
    border: 3px solid;
    opacity: .2
}

@keyframes spinner {
    0% { transform: rotate(0deg) }
    to { transform: rotate(359deg) }
}