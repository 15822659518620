.form-grid{
    display:grid;
    grid-gap: 1rem;
    grid-template-columns: repeat( auto-fill, minmax(150px, 2fr) );
    grid-auto-flow: dense;
}

@media only screen and (min-width: 992px) {
    .form-grid{
        grid-template-columns: repeat(10, 2fr);
    }
} 

.form-grid .form-group {
    grid-column: span 2 / auto;
}

.form-grid .grid-wide{
    grid-column: span 3 / auto;
}

.form-grid .grid-extra-wide{
    grid-column: span 4 / auto;
}

.form-button-grid{
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(0px, 200px));
    justify-content: end;
}


.form-button{
    grid-column: 1 / -1;
    display: grid;
    /* grid-template-columns: repeat( auto-fit, minmax(0px, 200px)); */
    justify-content: end;
}

#formFile-preview{
    outline: solid 1px black;
}

.form-score-grid{
    display:grid;
    grid-gap: 1rem;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: dense;
}

/* --------------------- */


.flatpickr-wrapper {
    width: 100%;
    position: relative;
  }
  
  .flatpickr-input {
    
    padding-right: 30px;
   
  }
  
  .flatpickr-icon-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 10px;
    z-index: 1;
    
  }


  /* --------------- */
/* toggle CSS */
.hide-sidebar{
    width: 80px!important;
    transition: width 0.5s ease-in-out;
}
.show-sidebar{
    width: 300px!important;
    transition: width 0.5s ease-in-out;
  
}
.hide-toggle-icon{
    cursor: pointer;
    position: relative;
    transform: translateX(calc(108% - 6vw));
    margin-left: 0; /* Set margin-left to 0 */
    padding-left: 45px;
    font-size: 20px;
    transition: width 0.5s ease-in-out;
}
.show-toggle-icon{
    cursor: pointer;
    position: relative;
    margin-left: auto;
    font-size: 20px;
    transition: width 0.5s ease-in-out;
    padding-right: 20px;
}

.toggle-icon{
    font-size: 20px;
}

.show-menu{
    display: block;
}

.hide-menu{
    display: none;
}


.display{
    display: flex;

}
/* 
  .flatpickr-calendar {
    background-color: transparent !important;
  }
   */
  .flatpickr-day:hover {
    background-color: #e6e6e6 !important;
  }
  
  .flatpickr-day.today {
    background-color: #d0e8ff !important;
  }

/* toggle CSS */

