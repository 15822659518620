.tooltip {
  position: relative;
  opacity: 1;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.gg-info {
  box-sizing: border-box;
  position: relative;
  display: inline-grid;
  transform: scale(var(--ggs,.8));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
  vertical-align: text-bottom;
}
.gg-info::after,
.gg-info::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px
}
.gg-info::after {
  bottom: 2px;
  height: 8px
}
.gg-info::before {
  height: 2px;
  top: 2px
}