.grid-card{
    display:grid;
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;
	grid-auto-flow: dense;
}

.card{
	grid-row: span 1 / auto;
	grid-column: span 1 / auto;
	border: 0px;
	background-color: rgba(0,0,0,0);
	grid-auto-flow: dense;
}

@media screen and (min-width: 600px) {
	.card-tall{
		grid-row: span 1 / auto;
	}

	.card-wide{
		grid-column: span 2 / auto;
	}
}

@media screen and (min-width: 800px) {
	.card-tall{
		grid-row: span 2 / auto;
	}

	.card-wide{
		grid-column: span 3 / auto;
	}
}

.card-inner{
	background-color: white;
	color:black;
	padding: 1rem;
	padding-top: 1rem;
	border-radius: 5%;
}

.card-inner-filled{
	background-color: var(--primary);
	color:white;
	padding: 1rem;
	padding-top: 1rem;
	border-radius: 5%;
}

.grid-calender{
	font-size: .75rem;
    display:grid;
    grid-template-columns: repeat( 7, minmax(12px, 1fr));
    grid-gap: .5rem;
	text-align: center;
}

.calender-item-active{
	border-radius: 50px;
	color: white;
	background-color: var(--primary);
}

h6 {
    font-weight: bold;
}

.bot-1 {
	bottom: 1rem;
}

#dot {
	height: 12px;
	width: 12px;
	border-radius: 50%;
	display: inline-block;
	margin-left: .5rem;
	margin-right: .5rem;
}

#employee-course-percent {
	/* display: flex;
	max-width: 100px;
	top: -9.5rem;
	left: 3rem;
	text-align: center;
	position: relative;
	z-index: 500; */
	font-size: calc(1.375rem + 1.5vw);
	font-weight: bolder;
}